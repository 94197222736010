import React from "react"
import cloudinaryString from "../helpers/cloudinaryString"

const Modal = ({ closeModal, story }) => {
  const modalContainer = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#0000007d",
    width: "100%",
    height: "100%",
    zIndex: "1000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  }
  const flyerContainer = {
    width: "clamp(350px, 50%, 550px)",
    backgroundColor: "#01567D",
    position: "relative  ",
    padding: "2.5rem 1rem",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "90vh",
    overlowY: "scroll",
  }

  const closeButton = {
    position: "absolute",
    right: "0",
    top: 0,
  }

  const button = {
    background: "",
    fontSize: "2rem",
    border: "none",
    background: "rgb(202, 54, 37)",
    color: "white",
  }

  const cta = {
    color: "rgb(0, 86, 125)",
    backgroundColor: "white",
    padding: ".75rem 1.5rem",
    marginLeft: "1rem",
    cursor: "pointer",
    minWidth: "270px",
    textAlign: "center",
    display: "inline-block",
    ":hover": {
      backgroundColor: "#01567D",
      color: "white",
      transition: "all .5s",
    },
  }
  return (
    <div style={modalContainer}>
      <div style={flyerContainer}>
        <div style={closeButton}>
          <button style={button} onClick={() => closeModal()}>
            X
          </button>
        </div>
        {story.media.length > 0 ? (
          <img
            src={cloudinaryString(story.media[0].cloudinaryId)}
            alt={story.title}
            style={{ height: "100%", maxHeight: "650px" }}
          />
        ) : (
          <h2
            className="title"
            style={{
              textAlign: "center",
              color: "white",
            }}
          >
            {story.title}
          </h2> // Fallback if no image is provided
        )}
        <p>{story.description}</p>
        {story.ctaLinks.map((link, index) => (
          <div style={{ margin: ".75rem 0" }}>
            <a
              key={index}
              style={cta}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Modal
